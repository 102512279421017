import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import GiftIcon from '../assets/img/gift.png'
import dayjs from 'dayjs';

const AllSetModal = ({ show, handleClose }) => {
  return (
  <Modal show={show} onHide={handleClose} centered>
    <Modal.Body className="text-center">
      <div className="modal-content-custom">
        {/* Red Gift Icon */}
        <div className="gift-icon mb-3 mt-5">
          <img src={GiftIcon} alt="Gift" />
        </div>

        {/* All set message */}
        <h4 className="font-weight-bold mt-4">All set!</h4>
        <p>{dayjs().format('DD / MM / YYYY, HH:mm')}</p>

        {/* Close Button */}
        <Button variant="danger" className="close-button mt-5" onClick={handleClose}>
          Close <span className="arrow">→</span>
        </Button>
      </div>
    </Modal.Body>
  </Modal>
  );
};

export default AllSetModal;
