import React, { useEffect, useState } from 'react'
import { t } from 'i18next'

// Components
import APIService from '../networks/APIService'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loader from './Loader/Loader'
import { toast } from 'react-toastify'

// Assets
import Icon from '../assets/img/Website Assets/Icons/Fleche Swap - noir.png'
import NewsImage from '../assets/img/Website Assets/GTO Carousel/GTO 1.png'
import GTOImage from '../assets/img/Website Assets/GTO Carousel/GTO 2.png'
import CollectionImage from '../assets/img/Website Assets/Collection Pics/GTO Collection Computer.png'
import GTOFourImage from '../assets/img/Website Assets/GTO Carousel/GTO 4.png'
import FlecheImage from '../assets/img/Website Assets/Icons/Fleche.png'
import BurgerIcon from '../assets/img/Website Assets/Icons/burger-bar.png'


const Ma_collection = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [collections, setCollections] = useState([])
  const [loading, setLoading] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const temp = localStorage.getItem('user');
  const auth = JSON.parse(temp);
  const linkToken = window.location.search.split('?token=')[1]

 

  useEffect(() => {
    const trackPageView = (url) => {
      if (window.gtag) {
        window.gtag('config', 'G-P5YDKHY4V6', {
          page_path: url,
        });
      }
    };

    trackPageView(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      // Adjust the threshold as needed
      const threshold = 670;

      if (documentHeight - scrollPosition - windowHeight < threshold) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (linkToken === undefined) {
      verifyToken(auth.userToken)
    } else {
      verifyToken(linkToken)
    }
  }, [])

  const verifyToken = async (token) => {
    setLoading(true)
    const data = {
      token: token
    }
    APIService.verifyToken(data).then((response) => {
      setLoading(false)
      if (response.success === true) {
        localStorage.setItem('user', JSON.stringify(response.user[0]))
        getCollectionsProducts(token)
        toast(t('User is verified'))
      } else {
        toast(t('User is not verified. please try again later.'))
        setTimeout(() => {
          localStorage.removeItem('user');
          window.close()
        }, 3000);
      }
    }
    ).catch((error) => {
      if (!error.success) {
        toast(t('User is not verified. please try again later.'))
        setTimeout(() => {
          window.close()
        }, 3000);
      }
      setLoading(false)
      console.error(error);
    });
  }

  useEffect(() => {
    setLoading(true)
  }, [])

  const getCollectionsProducts = async (userToken) => {
    APIService.getCollections(userToken).then((response) => {
      setLoading(false)
      const allSerialNumbers = response.product.map((item) => item.serialNumber)
      localStorage.setItem('userSerialNumber', JSON.stringify(allSerialNumbers));
      setCollections(response.product)
    }
    ).catch((error) => {
      setLoading(false)
      console.error(error);
    });

  }

  const handleGetNfcNumber = async (selectedItem) => {
    try {
      if (selectedItem.serialNumber === "") {
        toast(t('This Product does not have NFC Number'))
      } else {
        const link = `/home/product/${selectedItem.serialNumber}`
        navigate(link)
      }
    } catch (error) {
      toast(t('somethingWentWrong'))
      console.log(error);
    }
  }

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };


  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('userSerialNumber');
    window.location.href = 'https://1-art.co/account/login';
  }
  return (
    <div class="container" style={{marginTop:80}}>
      <ul class="d-flex justify-content-start justify-content-sm-between align-items-center list-unstyled m-0 mx-2 hidden_in_desktop">
                <li class="cursor_pointer">
                <a href="https://1-art.co/#concept" className='m-0 fw-normal cursor_pointer text-decoration-none text-dark'>
                  <h6
                    class="m-0 fw-normal cursor_pointer"
                  >
                    {t('Concept')}
                  </h6>
                  </a>
                </li>
                <li class="ms-5 cursor_pointer">
                <a href="https://1-art.co/#collection" className='m-0 fw-normal cursor_pointer text-decoration-none text-dark'>
                  <h6 class="m-0 fw-normal cursor_pointer">
                    {t('Collection')}
                  </h6>
                  </a>
                </li>
                <li class="ms-5 cursor_pointer hidden_in_mobile" >
                  <Link to={auth?.id ? "/collection" : "/login"} style={{ textDecoration: "none", color: 'black' }}>
                    <h6
                      class="m-0 fw-bold cursor_pointer"
                    >
                      {t('myCollection')}
                    </h6>
                  </Link>
                </li>
                </ul>
      <h2 class="fw-bold text-center mb-5" style={{marginTop:20}}>{t('myCollection')}</h2>
      <div class="row">
        {collections && collections.length > 0 && collections.map((collection,index) => {
          const fileExtension = collection?.productImages?.[0].substring(collection?.productImages?.[0].lastIndexOf('.') + 1);
          if (fileExtension === 'mp4') {
            return (
              <div class="col-md-4 col-sm-6 col-xs-12 mt-4">
              <div class="collection_in_mobile me-5 w-100 h-100 d-flex align-items-center bg-dark overflow-hidden" style={{borderRadius: 18}} onClick={() => handleGetNfcNumber(collection)}>
              <div className=' video-container'>
              <video autoPlay={true} muted className="add-item-img me-5 h-100 bg-black rounded-0" style={{borderRadius: 18}} >
                <source src={collection?.productImages?.[0]} type="video/mp4"  />
                Your browser does not support the video tag.
              </video>
              </div>
                <h4 className='collection-name'>{collection.productName}</h4>
                
              </div>
            </div>
            )
          } else {
          return (
            <div class="col-md-4 col-sm-6 col-xs-12 mt-4">
              <div class="collection_in_mobile w-100 image-main" onClick={() => handleGetNfcNumber(collection)}>
                <img
                  src={collection.productImages[0]}
                  className="add-item-img"
                  height={250}
                />
                <h4 className='collection-name'>{collection.productName}</h4>
                
              </div>
            </div>
          )
        }
        })}
      </div>
      
      {/* <div class="row p-5 cart_padd_in_mobile">
        <div class="col-md-1"></div>
        {loading ? <div><Loader /></div> :
          <div class="col-md-10">
            <div class="ma_collection_main">
              <h2 class="fw-normal text-center mb-5">{t('myCollection')}</h2>
              <div class="d-flex justify-content-center flex-wrap">
                {collections && collections.length > 0 && collections.map((collection) => {
                  return (
                    <div class="items mb-5">
                      <div className='d-flex flex-md-row flex-sm-column flex-wrap'>
                        <div class="collection_in_mobile me-5" onClick={() => handleGetNfcNumber(collection)}>
                          <img
                            src={collection.productImages[0]}
                            class="add-item-img me-5"
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        }
        <div class="col-md-1"></div>
      </div> */}
      <div id="fixedBottomContainer" style={{ position: 'relative' }}>
                <div class={`hidden_in_mobile my-5 ${isFixed ? 'fixed_bottom' : 'fixed_bottom'}`}>
                  <div class={`row ${menuVisible ? 'd-none' : 'information_toggle'}`}>
                    <div class="col-md-12 col-lg-12">
                      <div class="menu">
                        <ul class="d-flex justify-content-between list-unstyled m-0">
                          <li class="me-5 cursor_pointer">
                          <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                            <h6
                              class="m-0 fw-normal cursor_pointer"
                            >
                              {t('news_on_instragram')}
                            </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div
                                class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                              >
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="container text-center my-3">
                                      <div
                                        class="row mx-auto my-auto justify-content-center"
                                      >
                                        <div
                                          id="recipeCarousel"
                                          class="carousel slide"
                                          data-bs-ride="carousel"
                                        >
                                          <div class="carousel-inner" role="listbox">
                                            <div class="carousel-item active">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={NewsImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={CollectionImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    {/* <img
                                                    src={teamImage}
                                                    class="img-fluid"
                                                  /> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="carousel-item">
                                              <div class="col-md-3 me-1">
                                                <div class="card">
                                                  <div class="card-img">
                                                    <img
                                                      src={GTOFourImage}
                                                      class="img-fluid"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="prevContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="prev"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_left"
                                              />
                                            </a>
                                          </div>
                                          <div class="nextContainer_carousel">
                                            <a
                                              class="next d-flex justify-content-center align-items-center"
                                              href="#recipeCarousel"
                                              role="button"
                                              data-bs-slide="next"
                                            >
                                              <img
                                                src={Icon}
                                                class="w_swap_right"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                            <h6
                              class="m-0 fw-normal cursor_pointer information_onClick"
                              onClick={toggleMenu}
                            >
                              {t('information')}
                            </h6>
                          </li>
                          <li class="mx-5">
                          <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                            <h6
                              class="m-0 fw-normal cursor_pointer"
                            // data-bs-toggle="modal"
                            // data-bs-target="#exampleModal1"
                            >
                              {t('Contact')}
                            </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal1"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>

                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Entreprise')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('reasonForRequest')}</label>
                                            <select
                                              class="form-select custom-select w_400 w-100"
                                              id="inputGroupSelect01"
                                            >
                                              <option value="1">{t('customerService')}</option>
                                              <option value="2">
                                                {t('pressCommunication')}
                                              </option>
                                              <option value="3">
                                                {t('partnerShip')}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label class="switch me-2" for="checkbox">
                                                <input type="checkbox" id="checkbox" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t("conditions d'utilisation.")}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5">
                          <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                            <h6 class="m-0 fw-normal cursor_pointer">
                              {t('artist')}
                            </h6>
                            </Link>
                            <div
                              class="modal fade"
                              id="exampleModal2"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog modal-dialog-centered w_85">
                                <div class="modal-content">
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="contact-img position-relative">
                                          {/* <img
                                          src={teamImage}
                                          class="contact-img-border"
                                        /> */}
                                          <div
                                            class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                          >
                                            <h1 class="fw-normal m-0">
                                              {t('weWouldLike')}
                                              <span class="text_color_red"
                                              >{t('meet')}</span>
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-5">
                                        <div class="contact-border bg-white pt-4 pb-2">
                                          <h2 class="text-center fw-normal mb-4">
                                            {t('Contact')}
                                          </h2>
                                          <div
                                            class="d-flex justify-content-between mb-3"
                                          >
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15 position-absolute px-3"
                                              >{t('Nom')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_184_h"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                            <div class="position-relative">
                                              <label
                                                for="formGroupExampleInput"
                                                class="form-label bg-white start_15_contact position-absolute px-3"
                                              >{t('firstName')}</label>
                                              <input
                                                type="text"
                                                class="form-control w_174"
                                                id="formGroupExampleInput"
                                              />
                                            </div>
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('E-mail')}</label>
                                            <input
                                              type="text"
                                              class="form-control w_400 w-100"
                                              id="formGroupExampleInput"
                                            />
                                          </div>
                                          <div class="position-relative mb-3">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('Message')}*</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div class="position-relative mb-2">
                                            <label
                                              for="formGroupExampleInput"
                                              class="form-label bg-white start_15 position-absolute px-3"
                                            >{t('photos')}</label>
                                            <textarea
                                              type="text"
                                              rows="3"
                                              class="form-control w_400_area w-100"
                                              id="formGroupExampleInput"
                                            ></textarea>
                                          </div>
                                          <div
                                            class="d-flex justify-content-between align-items-center mb-2 text-start"
                                          >
                                            <div class="w-auto">
                                              <label
                                                class="switch me-2"
                                                for="checkbox2"
                                              >
                                                <input type="checkbox" id="checkbox2" />
                                                <div class="slider round"></div>
                                              </label>
                                            </div>
                                            <p class="lh-sm switch_p m-0">
                                              {t('accept')}
                                              <a href="#" class="text-decoration-none"
                                              >{t(`conditions d'utilisation.`)}</a>
                                            </p>
                                          </div>
                                          <a
                                            href="password.html"
                                            class="btn btn-register d-flex justify-content-center align-items-center w-100"
                                          >
                                            <p class="lh-sm text-start m-0">{t('send')}</p>
                                            <img
                                              src={FlecheImage}
                                              width="60"
                                            />
                                          </a>
                                          <p class="my-2">* : {t('mandatory')}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li class="mx-5 d-flex justify-content-between list-unstyled m-0">
                              <h6 class="m-0 fw-normal" >{t('Suivre 1-ART')}</h6>
                              <li class="mx-3"><a target="_blank" href='https://www.linkedin.com/company/1-art' style={{ color: '#000' }}><i class="fa-brands fa-linkedin"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.instagram.com/1_art.co/' style={{ color: '#000' }}><i class="fa-brands fa-instagram"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.facebook.com/profile.php?id=61552625361097' style={{ color: '#000' }}><i class="fa-brands fa-facebook"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.pinterest.fr/1ArtMonaco/' style={{ color: '#000' }}><i class="fa-brands fa-pinterest-p"></i></a></li>
                        <li class="mx-3"><a target="_blank" href='https://www.youtube.com/@1-ART_Official' style={{ color: '#000' }}><i class="fa-brands fa-youtube"></i></a></li>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>

                  {menuVisible && (
                    <ul
                      class="d-flex justify-content-between list-unstyled information_menu"
                    >
                      <li class="me-5 cursor_pointer">
                        <h6 class="m-0 fw-normal cursor_pointer" onClick={toggleMenu}>{t('information')}</h6>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                          <h6 class="m-0 cursor_pointer">{t('order_tracing')}</h6>
                        </Link>
                      </li>
                      <li class="me-5 cursor_pointer">
                        <Link className="text-decoration-none text-black" to="/faq">
                          <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                        </Link>
                      </li>

                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/privacy-policy">
                          <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                        </Link>
                      </li>
                      <li class="me-5">
                        <Link className="text-decoration-none text-black" to="/terms-and-service">
                          <h6 class="m-0 cursor_pointer">
                            {t('termOfService')}
                          </h6>
                        </Link>
                      </li>
                    </ul>)}
                </div>
              </div>

      <div
              class="d-flex justify-content-between fixed-bottom bg-white hidden_in_desktop px-3"
            >
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasTop"
                aria-controls="offcanvasTop"
              >
                <span class="navbar-toggler-icon">
                  <img
                    src={BurgerIcon}
                    width="26"
                    height="25"
                  />
                </span>
              </button>
              <ul class="d-flex justify-content-evenly list-unstyled" style={{marginTop:30,marginLeft:10}}>
                  <h6 class="m-0 cursor_pointer me-3">{t('Suivre 1-ART')}</h6>
                  <li class="mx-1"><a href='https://www.linkedin.com/company/1-art' style={{color:'#000'}}><i class="fa-brands fa-linkedin"></i></a></li>
                  <li class="mx-1"><a href='https://www.instagram.com/1_art.co/' style={{color:'#000'}}><i class="fa-brands fa-instagram"></i></a></li>
                  <li class="mx-1"><a href='https://www.facebook.com/profile.php?id=61552625361097' style={{color:'#000'}}><i class="fa-brands fa-facebook"></i></a></li>
                  <li class="mx-1"><a href='https://www.pinterest.fr/1ArtMonaco/' style={{color:'#000'}}><i class="fa-brands fa-pinterest-p"></i></a></li>
                  <li class="mx-1"><a href='https://www.youtube.com/@1-ART_Official' style={{color:'#000'}}><i class="fa-brands fa-youtube"></i></a></li>
              </ul>

              <div
                class="offcanvas offcanvas-start"
                tabindex="-1"
                id="offcanvasTop"
                aria-labelledby="offcanvasTopLabel"
              >
                <div class="offcanvas-header">
                  <button
                    type="button"
                    class="btn-close text-reset m-0"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="offcanvas-body" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                  <div class="menu">
                    <ul
                      class="d-flex flex-column align-items-center list-unstyled mt-5"
                    >
                    <li class="mb-5" >
                      <Link to={auth?.id ? "/collection" : "/login"} style={{ textDecoration: "none", color: 'black' }}>
                        <h6
                          class="m-0 cursor_pointer"
                        >
                          {t('myCollection')}
                        </h6>
                      </Link>
                    </li>
                      <li class="mb-5">
                      <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/pages/instagram">
                        <h6
                          class="m-0 cursor_pointer"
                        >
                          {t('news_on_instragram')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered modal-lg modal-xl"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="container text-center my-3">
                                  <div
                                    class="row mx-auto my-auto justify-content-center"
                                  >
                                    <div
                                      id="recipeCarousel m-0 p-0"
                                      class="carousel slide"
                                      data-bs-ride="carousel"
                                    >
                                      <div class="carousel-inner" role="listbox">
                                        <div class="carousel-item active">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={NewsImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={CollectionImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                {/* <img
                                                src={teamImage}
                                                class="img-fluid"
                                              /> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="carousel-item">
                                          <div class="col-md-3 me-1">
                                            <div class="card">
                                              <div class="card-img">
                                                <img
                                                  src={GTOFourImage}
                                                  class="img-fluid"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="prevContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="prev"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_left"
                                          />
                                        </a>
                                      </div>
                                      <div class="nextContainer_carousel">
                                        <a
                                          class="next d-flex justify-content-center align-items-center"
                                          href="#recipeCarousel"
                                          role="button"
                                          data-bs-slide="next"
                                        >
                                          <img
                                            src={Icon}
                                            class="w_swap_right"
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                        <h6
                          class="m-0 cursor_pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalResponsive1"
                        >
                          {t('information')}
                        </h6>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive1"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-dialog-centered bg-white w_85"
                          >
                            <div class="modal-content">
                              <div class="modal-body">
                                <ul
                                  class="d-flex flex-column align-items-center list-unstyled"
                                >
                                  <li class="mb-5 cursor_pointer">
                                    <h6 class="m-0 fw-normal cursor_pointer">
                                      {t('information')}
                                    </h6>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                  <Link target="_blank" className="text-decoration-none text-black" to="https://1-art.co/apps/parcelpanel">
                                    <h6 class="m-0 cursor_pointer">
                                      {t('order_tracing')}
                                    </h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5 cursor_pointer">
                                  <Link className="text-decoration-none text-black" to="/faq">
                                    <h6 class="m-0 cursor_pointer">{t('FAQ')}</h6>
                                    </Link>
                                  </li>
                             
                                  <li class="mb-5">
                                  <Link className="text-decoration-none text-black" to="/privacy-policy"> 
                                    <h6 class="m-0 cursor_pointer">{t('privateLife')}</h6>
                                    </Link>
                                  </li>
                                  <li class="mb-5">
                                  <Link className="text-decoration-none text-black" to="/terms-and-service">
                                    <h6 class="m-0 cursor_pointer">
                                      {t('termOfService')}
                                    </h6>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                      <Link className="text-decoration-none text-black" to="https://1-art.co/?contact=true">
                        <h6
                          class="m-0 cursor_pointer"
                          // data-bs-toggle="modal"
                          // data-bs-target="#exampleModalResponsive2"
                        >
                          {t('Contact')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive2"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div
                                        class="transform_img_layer d-flex align-items-center position-absolute bottom-0"
                                      >
                                        <h1 class="fw-normal m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red">{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-normal mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div
                                        class="d-flex justify-content-between mb-3"
                                      >
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>

                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('bussiness')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('reasonForRequest')}</label>
                                        <select
                                          class="form-select custom-select w_400 w-100"
                                          id="inputGroupSelect01"
                                        >
                                          <option value="1">{t('customerService')}</option>
                                          <option value="2">
                                            {t('pressCommunication')}
                                          </option>
                                          <option value="3">
                                            {t('partnerShip')}
                                          </option>
                                        </select>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Vente')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"
                                        ></textarea>
                                      </div>
                                      <div
                                        class="d-flex justify-content-between align-items-center mb-2 text-start"
                                      >
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox">
                                            <input type="checkbox" id="checkbox" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5">
                      <Link className="text-decoration-none text-black" to="https://1-art.co/?artist-contact=true">
                        <h6 class="m-0 cursor_pointer">
                          {t('artist')}
                        </h6>
                        </Link>
                        <div
                          class="modal fade"
                          id="exampleModalResponsive3"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered w_85">
                            <div class="modal-content">
                              <div class="modal-body">
                                <div class="row">
                                  <div class="col-md-7 hidden_in_mobile">
                                    <div class="contact-img position-relative">
                                      {/* <img
                                      src={teamImage}
                                      class="contact-img-border"
                                    /> */}
                                      <div class="transform_img_layer d-flex align-items-center position-absolute bottom-0">
                                        <h1 class="fw-normal m-0">
                                          {t('weWouldLike')}
                                          <span class="text_color_red"
                                          >{t('meet')}</span>
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-5">
                                    <div class="contact-border bg-white pt-4 pb-2">
                                      <h2 class="text-center fw-normal mb-4">
                                        {t('Contact')}
                                      </h2>
                                      <div class="d-flex justify-content-between mb-3">
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15 position-absolute px-3"
                                          >{t('Nom')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_184_h"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                        <div class="position-relative">
                                          <label
                                            for="formGroupExampleInput"
                                            class="form-label bg-white start_15_contact position-absolute px-3"
                                          >{t('firstName')}</label>
                                          <input
                                            type="text"
                                            class="form-control w_174"
                                            id="formGroupExampleInput"
                                          />
                                        </div>
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('E-mail')}</label>
                                        <input
                                          type="text"
                                          class="form-control w_400 w-100"
                                          id="formGroupExampleInput"
                                        />
                                      </div>
                                      <div class="position-relative mb-3">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('Message')}*</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="position-relative mb-2">
                                        <label
                                          for="formGroupExampleInput"
                                          class="form-label bg-white start_15 position-absolute px-3"
                                        >{t('photos')}</label>
                                        <textarea
                                          type="text"
                                          rows="3"
                                          class="form-control w_400_area w-100"
                                          id="formGroupExampleInput"></textarea>
                                      </div>
                                      <div class="d-flex justify-content-between align-items-center mb-2 text-start">
                                        <div class="w-auto">
                                          <label class="switch me-2" for="checkbox2">
                                            <input type="checkbox" id="checkbox2" />
                                            <div class="slider round"></div>
                                          </label>
                                        </div>
                                        <p class="lh-sm switch_p m-0">
                                          {t('accept')}
                                          <a href="#" class="text-decoration-none"
                                          >{t("conditions d'utilisation.")}</a>
                                        </p>
                                      </div>
                                      <a
                                        href="#"
                                        class="btn btn-register d-flex justify-content-center align-items-center w-100">
                                        <p class="lh-sm text-start m-0">{t('send')}</p>
                                        <img
                                          src={FlecheImage}
                                          width="60"
                                        />
                                      </a>
                                      <p class="my-2">* : {t('mandatory')}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="mb-5" >
                      <div onClick={handleLogout} style={{ textDecoration: "none", color: 'black' }}>
                        <h6 class="m-0 cursor_pointer"
                        >
                          {t('logOut')}
                        </h6>
                      </div>
                    </li>
                    </ul>
                  </div>
                  <a href="https://1-art.co/account" class="h5 text-decoration-none" style={{backgroundColor:'#1d1d1b08',padding: '1rem 2rem'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" class="icon icon-account" fill="none" viewBox="0 0 18 19" style={{
                        height: '2rem',
                        width: '2rem',
                        marginRight: '1rem'
                      }}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.5a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm5.58 12.15c1.12.82 1.83 2.24 1.91 4.85H1.51c.08-2.6.79-4.03 1.9-4.85C4.66 11.75 6.5 11.5 9 11.5s4.35.26 5.58 1.15zM9 10.5c-2.5 0-4.65.24-6.17 1.35C1.27 12.98.5 14.93.5 18v.5h17V18c0-3.07-.77-5.02-2.33-6.15-1.52-1.1-3.67-1.35-6.17-1.35z" fill="currentColor">
                          </path>
                      </svg>Account
                    </a>
                </div>
              </div>
      </div>
    </div>
  )
}

export default Ma_collection